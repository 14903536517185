<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit
          :form="purchaseReturn"
          :vendors="vendors"
          :listInvoice="listInvoice"
          ref="formEdit"
          v-if="!edit"
        ></form-edit>
        <detail :id="id" :item="purchaseReturn" v-if="edit"></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-btn color="success" @click="updateItem">
              <v-icon>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-btn color="success" @click="editItem">
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-btn color="error" @click.stop="deleteDialog">
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-col>
        </v-row> -->
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-delete
      :id="id"
      :dialog="dialog"
      :returnNumber="purchaseReturn.returnNumber"
      title="Purchase Return"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-delete>
  </form>
</template>

<script>
import Detail from "./DetailPurchaseReturn";
import FormEdit from "./FormPurchaseReturn";
import DialogDelete from "@/components/DialogDelete";
import { mapState } from "vuex";

export default {
  name: "purchase-return",
  components: {
    Detail,
    "form-edit": FormEdit,
    "dialog-delete": DialogDelete,
  },
  props: {
    id: {
      type: Number,
      default: () => undefined,
    },
  },
  data: () => ({
    edit: true,
    dialog: false,
    listInvoice: [],
    purchaseReturn: {},
  }),
  computed: {
    ...mapState("external", ["vendors"]),
  },
  methods: {
    async getPurchaseReturn() {
      await this.$store
        .dispatch("purchaseReturn/getById", this.id)
        .then(response => (this.purchaseReturn = response.data));
      await this.$store
        .dispatch("purchaseReturn/getListInvoiceById", this.id)
        .then(response => (this.listInvoice = response.data));
      this.$store.dispatch("external/getVendors");
    },
    back() {
      this.$router.go(-1);
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.update();
    },
    deleteItem(id) {
      this.$store.dispatch("purchaseReturn/delete", id);
      this.dialog = false;
    },
    deleteDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    cancel() {
      this.getPurchaseReturn();
      this.edit = true;
    },
  },
  mounted() {
    this.getPurchaseReturn();
  },
};
</script>

<style></style>
